//
// Header Style
//
.header {
  background: #003F31;
  color: #ffffff;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  @media (max-width: 767px) {
    height: 65px; }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between; }

  &::before {
    content: '';
    width: 100%;
    height: 6px;
    background: image('footer-gradient.png') repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0; }

  .logo {
    padding-bottom: 6px;
    height: 100%;
    align-items: center;
    display: flex;

    img {
      border-radius: 50%;
      width: 100%;
      @media (max-width: 767px) {
        max-width: 65px; } } }

  .menu-navigation {
    height: 100%;
    flex-wrap: nowrap;
    flex: 1;

    .main-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      justify-content: flex-end;
      @media (max-width: 1199px) {
        flex-direction: column; }

      > li {
        height: 100%;

        &:nth-child(2) {
          .dropdown {
            > .dropdown-menu {
              min-width: 15rem; } } }

        > a {
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          height: 100%;
          display: flex;
          align-items: center;
          text-decoration: none;
          transition: 0.3s ease;
          padding: 0;
          justify-content: center;
          padding: 6px 20px;
          @media (max-width: 1199px) {
            padding: 10px 0;
            justify-content: flex-start;
            border-bottom: 1px solid #ffffff; } }


        .dropdown {
          height: 100%;

          @media (max-width: 1199px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start; }

          &:hover {
            > .dropdown-menu {
              opacity: 1;
              visibility: visible; } }

          .dropdown-toggle {
            height: 100%;
            padding: 0;
            background-color: transparent;
            outline: none;
            box-shadow: none;
            border: none;
            color: #ffffff;
            font-weight: 500;
            font-size: 14px;
            transition: 0.3s ease;
            border-radius: 0;
            position: relative;
            width: 100%;
            padding: 6px 20px;

            @media (max-width: 1199px) {
              width: 100%;
              text-align: left;
              padding: 10px 0;
              border-bottom: 1px solid #ffffff; }

            &::before {
              content: '';
              width: 8px;
              height: 8px;
              border-right: 1px solid #ffffff;
              border-top: 1px solid  #ffffff;
              display: inline-block;
              transform: rotate(135deg);
              transition: 0.3s;
              right: 15px;
              top: 15px;
              position: absolute;
              opacity: 0;
              visibility: hidden;
              @media (max-width: 1199px) {
                opacity: 1;
                visibility: visible; } }

            &::after {
              display: none; } } }

        .dropdown-menu {
          display: block;
          opacity: 0;
          visibility: hidden;
          transition: 0.3s ease;
          background-color: #228E40;
          border-radius: 0;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
          margin: 0;
          border: none;
          padding: 15px;
          min-width: 11rem;
          @media (max-width: 1199px) {
            display: none;
            opacity: 1;
            visibility: visible;
            transition: none;

            &.show {
              display: block !important;
              position: relative !important;
              transform: none !important;
              background-color: transparent;
              border-radius: 0;
              width: 100%;
              padding: 0;
              padding-left: 15px;

              a {
                padding: 10px 0;
                border-bottom: 1px solid #ffffff; } } }

          a {
            color: #ffffff;
            font-size: 13px;
            text-decoration: none;
            display: block;
            padding: 0;
            &:hover {
              color: #E8BE70; } }

          li {
            margin-bottom: 8px;
            padding: 0;
            &:last-child {
              margin-bottom: 0; }

            p {
              color: #ffffff;
              font-size: 13px;
              @media (max-width: 1199px) {
                padding: 10px 0;
                border-bottom: 1px solid #ffffff;
                margin: 0; } }

            ul {
              padding-left: 10px;
              list-style: none;

              li {
                opacity: 0.7; } } } } } }

    .header-search {
      display: flex;
      padding: 0 15px;
      align-items: center;
      font-size: 20px;
      cursor: pointer;
      position: relative;
      @media (max-width: 1199px) {
        display: none; } } }

  .search-mobile {
    display: none;
    @media (max-width: 1199px) {
      font-size: 24px;
      cursor: pointer;
      height: 100%;
      display: flex;
      padding: 6px 20px;
      align-items: center; }
    @media (max-width: 767px) {
      padding: 0 20px 6px; } }


  .burger {
    display: none; }

  @media (max-width: 1199px) {
    .logo {
      position: relative;
      z-index: 999; }

    .mobile-menu {
      display: flex;
      height: 100%;
      align-items: center; }

    .burger {
      background-color: transparent;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      display: block;
      height: 100%;
      padding: 0;
      position: relative;
      width: 36px;
      z-index: 1000;
      transition: all 300ms ease-out;
      outline: none;
      margin-left: auto; }

    .burger-wrapper {
      position: absolute;
      width: 100%;
      // height: 100%
      top: 0;
      left: 0;
      bottom: 6px; }

    .x,
    .y,
    .z {
      position: absolute;
      margin: auto;
      top: 0px;
      bottom: 16px;
      left: 0;
      right: 0;
      background: #ffffff;
      transition: all 200ms ease-out; }

    .x,
    .y,
    .z {
      width: 30px;
      height: 2px; }

    .y {
      top: 16px; }

    .z {
      top: 32px; }

    .collapse-icon {
      background: #ffffff;
      top: 17px;
      width: 30px;
      transition: all 70ms ease-out; }

    .rotate30 {
      transform: rotate(30deg);
      transition: all 50ms ease-out; }

    .rotate150 {
      transform: rotate(150deg);
      transition: all 50ms ease-out; }

    .rotate45 {
      transform: rotate(45deg);
      transition: all 100ms ease-out; }

    .rotate135 {
      transform: rotate(135deg);
      transition: all 100ms ease-out; }

    .menu-bg {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #003F31;
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      transition: all 300ms cubic-bezier(0.000, 0.995, 0.990, 1.000); }

    .menu-bg.animate {
      opacity: 0.9;
      visibility: visible;
      transition: all 400ms cubic-bezier(0.000, 0.995, 0.990, 1.000); }

    .menu-navigation {
      width: 100%;
      max-width: 960px;
      padding: 0 20px 15px;
      opacity: 0;
      visibility: hidden;
      animation-name: zoomOut;
      animation-duration: .3s;
      position: absolute;
      z-index: 102;
      display: block;
      left: 0;
      right: 0;
      margin: auto;
      height: auto;
      top: 80px;
      max-height: calc(100vh - 80px);
      overflow-y: auto;
      @media (max-width: 767px) {
        top: 65px;
        max-height: calc(100vh - 65px); } }

    @-webkit-keyframes zoomOut {
      from {
        opacity: 1; }

      50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3); }

      to {
        opacity: 0; } }

    @keyframes zoomOut {
      from {
        opacity: 1; }

      50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3); }

      to {
        opacity: 0; } }


    .menu-navigation.animate {
      animation-name: zoomIn;
      animation-duration: .4s;
      opacity: 1;
      visibility: visible; }

    @-webkit-keyframes zoomIn {
      from {
        opacity: 0;
        transform: scale3d(.3, .3, .3); }

      50% {
        opacity: 1; } }


    @keyframes zoomIn {
      from {
        opacity: 0;
        transform: scale3d(.3, .3, .3); }

      50% {
        opacity: 1; } } } }

