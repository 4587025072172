// General Style
html {
  @media(max-width: 767px) {
    font-size: 14px; } }
body {
  // font-family: 'Heebo', sans-serif
  font-family: 'Avenir', sans-serif;

  // font-family: 'Montserrat', sans-serif
  color: #414141;
  position: relative; }

a {
  transition: 0.3s;
  outline: none; }
*:focus {
  outline: none!important; }
img {
  max-width: 100%; }
a,
button,
input[type=file],
input[type=file]::-webkit-file-upload-button {
    cursor: pointer; }

a:disabled,
button:disabled {
  opacity: .65;
  cursor: default; }

.main {
  > div {
    position: relative; } }

.btn-outline {
  border: none;
  box-shadow: inset 0px 0px 0px 1px #00513B;
  color: #00513B;
  width: 194px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-size: 13px;
  text-decoration: none !important;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  font-weight: 500;

  > span {
    position: relative;
    z-index: 1; }

  &:before {
    content: '';
    width: 100%;
    height: 0;
    background: rgb(246,233,184);
    background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out; }

  &:hover {
    box-shadow: none;
    color: #ffffff;

    &:before {
      height: 100%; } }

  &:disabled {
    box-shadow: inset 0px 0px 0px 1px#6c757d;
    color: #343a40;
    background-color: #6c757d;
    &:before {
      display: none; } }


  i {
    margin-left: 5px;
    font-size: 12px;
    margin-top: 2px; } }

.btn-gradient {
  border: none;
  background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
  border: none;
  color: #ffffff;
  width: 194px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-size: 13px;
  text-decoration: none !important;
  position: relative;
  overflow: hidden;
  font-weight: 500;

  &:hover {
    color: #ffffff; }

  > span {
    position: relative;
    z-index: 1; }
  i {
    margin-left: 5px;
    font-size: 12px;
    margin-top: 2px; } }

.top-title {
  position: relative;
  text-align: center;
  overflow: hidden;
  margin-bottom: 20px;
  span {
    color: #003F31;
    padding: 10px 20px;
    position: relative;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-family: 'Avenir';
    display: inline-block;
    font-weight: 300;
    @media (max-width: 580px) {
      font-size: 20px; }

    &::before, &::after {
      content: '';
      width: 200px;
      height: 1px;
      background-color: #D3D3D3;
      position: absolute;
      top: 48%;
      right: 100%; }

    &::after {
      right: auto;
      left: 100%; } } }


.page-title {
  font-weight: 300;
  color: #00513B;
  font-size: 35px;
  letter-spacing: 0.05em;
  @media (max-width: 767px) {
    font-size: 30px; } }

.page-subtitle {
  font-weight: 300;
  color: #B2B2B2!important;
  font-size: 18px;
  letter-spacing: 0.05em;
  margin: 0 0 35px;
  line-height: 1.5; }

.color-hover {
  color: #E8BE70 !important; }

.color-title {
  color: #00513B !important; }

.lts-1 {
  letter-spacing: 0.1em; }

.container {
  @media (min-width: 1280px) {
    max-width: 1200px; }
  @media (max-width: 1199px) {
    max-width: none;
    width: 100%; } }

.small-container {
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0 !important; } }

.background-fixed {
  height: 500px;
  position: relative;
  z-index: -1;
  margin-top: 80px;
  @media (max-width: 767px) {
    height: 350px;
    margin-top: 60px; }

  &-inner {
    position: fixed;
    height: 500px;
    overflow: hidden;
    width: 100%;
    @media (max-width: 767px) {
      height: 350px; } }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.deco-wave {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 80px;
  margin-top: -60px;
  @media (min-width: 1441px) {
    margin-top: -80px; }
  @media (max-width: 991px) {
    margin-top: -35px; }
  svg, img {
    width: 100%;
    min-width: 330px;
    object-fit: cover;
    object-position: bottom center;
    path {
      width: 100%; } } }

.deco-white {
  svg {
    fill: #ffffff; } }

.deco-gray {
  svg {
    fill: #FFFDFD; } }

.deco-dark-gray {
  svg {
    fill: #EFEFEF; } }

.title-heavy {
  span {
    font-weight: 900; } }

.content-detail-karyawan {
  h1, h2, h3, h4, h5 {
    color: #000000;
    font-weight: 500; }
  h1 {
    @media (max-width: 991px) {
      font-size: 2rem; } } }


.content-html, .content-wrapper {
  h1, h2, h3, h4, h5 {
    color: #003F31;
    font-weight: 500; }
  h1 {
    @media (max-width: 991px) {
      font-size: 2rem; } }

  &-inner {
    padding: 50px 0 60px;
    background-color: #FFFDFD;
    @media (max-width: 767px) {
      padding: 50px 0 30px; } } }

.card-rounded {
  border: 1px solid #CC9D33;
  border-radius: 16px;
  text-align: center;
  height: 100%;

  img {
    margin: 15px auto 30px; }
  h1,h2,h3,h4,h5 {
    font-weight: 900; }
  h1 {
    @media (max-width: 991px) {
      font-size: 2rem; } }
  h6 {
    font-size: 18px;
    font-weight: normal; }
  p {
    margin-bottom: 0;
    font-size: 15px; }
  ul {
    text-align: left;
    padding-left: 20px;
    font-size: 15px; } }

.mb-35 {
  margin-bottom: 35px!important; }

.maxh-50 {
  max-height: 50px;
  margin-bottom: 15px!important; }

.px-60 {
  padding: 30px 60px;
  @media (max-width: 991px) {
    padding: 30px 15px; }
  @media (max-width: 767px) {
    padding: 15px; } }

.px-30 {
  padding: 30px;
  @media (max-width: 991px) {
    padding: 15px; } }
.px-15 {
  padding: 15px; }

.col-20-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px; }

.col-20 {
  padding: 0 8px;
  flex: 0 0 20%;
  max-width: 20%;
  @media (max-width: 991px) {
    flex: 0 0 50%;
    max-width: 50%; }
  @media (max-width: 580px) {
    flex: 0 0 100%;
    max-width: 100%; } }
.-mt-3 {
  margin-top: -1rem!important; }

// .title-gray
//   span
//     background-color: #FFFDFD

.green-color {
  color: #00513B!important; }

.fw-500 {
  font-weight: 500; }


.card-lokasi {
  .green-color {
    font-size: 15px; } }

.list-contact {
  list-style: none;
  margin: 0;
  padding: 0!important;
  li {
    text-align: center; }
  .green-color {
    display: inline-block; } }


.history-years {
  padding-top: 40px;
  h3 {
    font-family: 'Heebo', sans-serif; }
  ul {
    list-style: none;
    padding: 30px 0;
    margin: 0 -25px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1199px) {
      margin: 0; }
    @media (max-width: 767px) {
      padding-bottom: 0; }

    &::before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      background: #cc9d33;
      background: linear-gradient(180deg, #cc9d33 0%, #f6e9b8 33%, #e8be70 66%, #cc9d33 100%);
      left: calc(50% - 2.5px);
      top: 0;
      border-radius: 16px;
      @media (max-width: 991px) {
        left: 14px; } }


    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 25px;
      margin-bottom: 30px;
      @media (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 50px;
        padding-right: 0; }

      &:nth-child(odd) {
        align-self: flex-end;
        .card-rounded {
          text-align: left; } }

      &::before {
        content: '';
        width: 36px;
        height: 36px;
        background: image('image-separator.png') no-repeat;
        background-size: contain;
        position: absolute;
        left: calc(50% - 15px);
        margin-top: 20px;
        transition: 0.3s;
        @media (max-width: 991px) {
          left: 0; } }

      .card-rounded {
        background-color: #fff;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
        margin-bottom: 15px;
        text-align: right;
        transition: 0.3s;
        @media (max-width: 991px) {
          text-align: left; } }

      &:hover {
        &::before {
          background: image('image-separator-hover.png') no-repeat;
          background-size: contain; }
        .card-rounded {
          background-color: #F6E9B8; } } } } }

.card-category {
  border-radius: 32px;
  overflow: hidden;
  position: relative;

  .thumbnail {
    width: 100%;
    > div {
      background-color: rgba(0, 81, 59, 0.75);
      background-size: cover!important;
      transition: 0.3s ease-out;
      background-blend-mode: multiply;
      // @media (max-width: 991px)
 }      //   transform: scale(1.1)
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-out;
      background-blend-mode: multiply;
      // @media (max-width: 991px)
 } }      //   transform: scale(1.1)

  .desc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    z-index: 2;

    .title {
      font-size: 36px;
      font-weight: 700;
      transition: 0.3s ease-out;
      transform: translateY(60px);
      // @media (max-width: 991px)
 }      //   transform: none

    p {
      overflow: hidden;
      font-weight: 300;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      max-width: 350px;
      font-size: 14px;
      // @media (max-width: 991px)
      //   visibility: visible
      //   opacity: 1
 }      //   height: auto


    .btn-gradient {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      // @media (max-width: 991px)
      //   visibility: visible
 } }      //   opacity: 1

  &:hover {

    .thumbnail {
      > div {
        background-color: rgba(190, 9, 9, 0.75);
        transform: scale(1.1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; }
      img {
        transform: scale(1.1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } }

    .desc {
      .title {
        transform: translateY(0); }

      p {
        opacity: 1;
        visibility: visible; }

      .btn-gradient {
        opacity: 1;
        visibility: visible; } } } }

.overlay-hover {
  border-radius: 0;
  &::before {
    content: '';
    background: rgba(1, 122, 90, .7);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .desc {
    .title {
      font-family: 'Avenir black';
      font-size: 70px;
      @media (max-width: 991px) {
        font-size: 36px; } } }

  &:hover {
    &::before {
      opacity: 1; } } }

.card-list {
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-out;
      transform: scale(1.1); } }

  .desc {
    position: absolute;
    width: 100%;
    top: calc(50% + 40px);
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    transition: 0.3s ease-out;
    transform: translateY(0);
    // @media (max-width: 991px)
    //   transform: translateY(calc(-50% - 40px))

    .title {
      font-size: 20px;
      font-weight: 500;
      transition: 0.3s ease-out;
      max-height: 72px;
      overflow: hidden;
      margin-bottom: 15px; }

    p {
      height: 70px;
      overflow: hidden;
      font-weight: 300;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      height: 70px;
      max-width: 350px;
      font-size: 14px;
      // @media (max-width: 991px)
      //   visibility: visible
      //   opacity: 1
 }      //   height: auto


    .btn-gradient {
      opacity: 0;
      visibility: hidden;
      // @media (max-width: 991px)
      //   visibility: visible
 } }      //   opacity: 1

  &:hover {
    &::before {
      opacity: 1; }

    .thumbnail {
      img {
        transform: scale(1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } }


    .desc {
      transform: translateY(calc(-50% - 40px));

      .btn-gradient {
        opacity: 1;
        visibility: visible; } } } }

.card-video {
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  .link-video-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    top: 0;
    left: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
    //   opacity: 1

    svg {
      width: 48px;
      height: 48px;
      path {
        fill: #ffffff; } } }

  &:hover {
    &::before, .link-video-detail {
      opacity: 1; } } }

.card-distributor {
  border: 2px solid #E8E7E7;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  color: #7E7E7E;

  ._header {
    background-color: #FAFAFA;
    padding: 15px 20px;
    font-weight: 700;
    font-size: 20px;
    color: #7E7E7E;
    @media (max-width: 767px) {
      font-size: 18px; } }

  ._content {
    padding: 20px;

    h4 {
      font-weight: normal;
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #7E7E7E;
      @media (max-width: 767px) {
        font-size: 18px; } } }

  ._location {
    a {
      display: flex;
      color: #00513B;
      text-decoration: none;
      transition: 0.3s ease-in;
      backface-visibility: hidden;
      transform-origin: 0 50%;
      margin-bottom: 5px;

      img, svg {
        width: 15px;
        margin-right: 10px;
        fill: #00513B; }

      &:hover {
        transform: scale(1.1); } }

    p {
      padding: 0 28px;
      @media (max-width: 767px) {
        font-size: 14px;
        padding-right: 0; } } }

  ._contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      flex-wrap: wrap; }

    img, svg {
      width: 15px;
      margin-right: 10px;
      fill: #00513B; }

    ._contact-name {
      color: #707070;
      font-weight: 700;
      flex-shrink: 0;
      padding-right: 15px;
      min-width: 120px;
      @media (max-width: 767px) {
        font-size: 14px; } }

    a {
      color: #00513B;
      font-style: italic;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 14px; } } } }

.card-traditional-stores {
  background-color: #EFEFEF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 767px) {
    padding: 30px; }


  h2 {
    color: #ffffff;
    margin: 0;
    text-transform: uppercase;
    font-weight: 900;
    position: absolute;
    background-color: #00513B;
    border-radius: 32px;
    font-size: 24px;
    line-height: 1.3;
    width: calc(100% - 20px);
    text-align: center;
    padding: 6px 10px;
    bottom: -22px;
    @media (max-width: 991px) {
      font-size: 20px; }
    @media (max-width: 767px) {
      font-size: 16px; } } }

.card-modern-stores {
  background-color: #EFEFEF;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  img {
    transition: 0.3s ease-in;
    backface-visibility: hidden;
    transform-origin: 50% 50%; }

  &:hover {
    img {
      transform: scale(1.1); } } }

a.card-modern-stores {
  display: block;
  background-color: #EFEFEF; }

.embed-responsive-16by11 {
  &::before {
    padding-top: 65.8%;
    @media (max-width: 991px) {
      padding-top: 75%; } } }

.embed-responsive-16by11\/2 {
  &::before {
    padding-top: 32.43%;
    @media (max-width: 991px) {
      padding-top: 75%; } } }

.embed-responsive-1by2 {
  &::before {
    padding-top: 120%; } }

.list-wrapper {
  margin: 0 -8px;
  @media (max-width: 767px) {
    margin: 0; }


  > div {
    padding: 0 8px;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      padding: 0; } } }

.paginations {
  padding: 60px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    padding: 30px 0 20px; }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #F6E9B8;
    border-radius: 30px;

    > li {
      > a, > span {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        color: #707070;
        text-decoration: none;
        line-height: 25px;
        margin-right: 10px;
        @media (max-width: 767px) {
          margin-right: 5px; } }

      &:last-child {
        > a, > span {
          margin-right: 0; } }

      > a {
        cursor: pointer;
        &:hover {
          color: #ffffff!important;
          background-color: #00513B; } }

      .active {
        color: #ffffff;
        background-color: #00513B; } } }

  .pagination-arrow {
    width: auto;
    margin: 0 5px;
    padding: 10px;
    border-radius: 10px;

    span {
      padding: 0 5px;
      @media (max-width: 991px) {
        display: none; } } } }

.btn-filter {
  // border: 1px solid #707070
  border: none;
  border-radius: 7px;
  box-shadow: inset 0px 0px 0px 2px #707070;
  background-color: transparent;
  outline: none!important;
  text-decoration: none!important;
  font-size: 14px;
  padding: 5px 20px;
  display: block;
  text-align: center;
  color: #707070;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  > span {
    position: relative;
    z-index: 1; }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(246,233,184);
    background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
    z-index: 0;
    opacity: 0;
    visibility: hidden; }
  &:hover {
    box-shadow: none;

    &:before {
      opacity: 1;
      visibility: visible; } }

  &.active {
    border-color:  #00513B;
    background-color:  #00513B;
    box-shadow: inset 0px 0px 0px 2px  #00513B;
    color: #ffffff;
    &:before {
      opacity: 0;
      visibility: hidden; } }

  // &:hover, &.active
  //   box-shadow: none
  //   color: #ffffff

  //   &:before
  //     opacity: 1
  //     visibility: visible
  // // &:hover,  &.active
  // //   background-color: #00513B
  // //   border-color: #00513B
 }  // //   color: #ffffff

.btn-more {
  background-color: #00513B;
  border-radius: 30px;
  border: none;
  text-decoration: none!important;
  font-size: 24px;
  font-weight: 500;
  padding: 4px 30px;
  display: inline-block;
  margin: auto;
  text-align: center;
  color: #ffffff;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  outline: none!important;
  @media (max-width: 767px) {
    font-size: 18px;
    padding: 4px 20px; }

  &:hover {
    color: #ffffff;
    background-color: #003f31; } }

.content-detail-inner {
  padding: 130px 0 100px;
  margin: 0 0 35px;
  border-bottom: 1px solid #6A6A6A;
  color: #707070;
  @media (max-width: 991px) {
    padding: 100px 0 80px; }
  @media (max-width: 767px) {
    padding: 60px 0; }

  img {
    margin: 20px 0;
    border-radius: 8px;
    width: 100%; }

  h1, h2, h3, h4, h5 {
    color: #00513B;
    font-weight: 500; }


  h1, h2 {
    font-weight: 900; }

  h1 {
    @media (max-width: 991px) {
      font-size: 2rem; } }

  b {
    font-size: 22px;
    margin: 20px 0;
    display: block;
    @media (max-width: 767px) {
      font-size: 18px; } }

  .date-wrapper {
    font-weight: 900;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #CC9D33;
    color: #6A6A6A; }

  .category {
    text-transform: uppercase;
    color: #BE0909;
    display: inline-block; }

  blockquote {
    border-left: 8px solid #CC9D33;
    padding-left: 15px;
    margin: 20px 0;
    font-weight: 900;
    color: #000000; }

  ul {
    padding-left: 30px; } }


.popular-title {
  font-size: 35px;
  text-align: center;
  font-weight: 800;
  color: #00513B;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 30px; } }

.badge-red {
  background-color: #00513B;
  border-radius: 7px;
  font-size: 14px;
  padding: 6px 20px;
  text-align: center;
  color: #ffffff; }

.button-group-wrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 15px;
    @media (max-width: 767px) {
      padding: 15px 0; } } }

.button-icon {
  display: flex;
  padding: 10px 25px;
  margin: 0 auto;
  border-radius: 15px;
  align-items: center;
  color: #7E7E7E;
  text-decoration: none !important;
  max-width: 400px;
  transition: 0.3s ease-in;
  @media (max-width: 767px) {
    padding: 10px 0; }

  &:hover {
    background-color: #003F31;
    border-color: #003F31;
    color: #ffffff;
    @media (max-width: 767px) {
      color: #7E7E7E;
      background-color: transparent;
      border-color: transparent; }

    svg {
      fill: #ffffff;
      @media (max-width: 767px) {
        fill: #003F31; } }
    p {
      color: #ffffff;
      @media (max-width: 767px) {
        color: #000; } } }

  img, svg {
    width: 40px;
    height: 40px;
    fill: #003F31;
    margin-right: 15px;
    flex-shrink: 0;
    transition: 0.3s ease-in;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      margin-right: 10px; } }

  p {
    font-size: 30px;
    font-weight: 900;
    margin: 0;
    color: #000;
    transition: 0.3s ease-in;
    @media (max-width: 767px) {
      font-size: 18px; } } }

.form-search {
  position: absolute;
  display: flex;
  background-color: #228E40;
  top: 100%;
  right: 0;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-width: 420px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  @media (max-width: 1199px) {
    border-radius: 0;
    width: 100%;
    padding: 20px 15px;
    min-width: auto; }

  input {
    flex: 1;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    padding: 5px 15px;
    outline: none!important; }

  .btn-search {
    border-radius: 20px;
    font-size: 16px;
    padding: 5px 20px;
    background-color: #00513B;
    outline: none!important;
    text-decoration: none!important;
    border: none;
    color: #ffffff;
    margin-left: 10px;
    display: block;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: auto; }
    &:hover {
      background-color:#003F31 {} } } }

.header-search {
  &:hover {
    .form-search {
      opacity: 1;
      visibility: visible;
      @media (max-width: 1199px) {
        opacity: 0;
        visibility: hidden; } } } }

.search-mobile {
  .form-search.show {
    visibility: visible;
    opacity: 1; } }

.form-search-result {
  display: flex;
  padding-top: 15px;
  input {
    flex: 1;
    border-radius: 20px;
    border: 2px solid #D3D3D3;
    font-size: 16px;
    padding: 5px 15px;
    outline: none!important;
    background-color: transparent; }

  .btn-search {
    border-radius: 20px;
    font-size: 16px;
    padding: 5px 20px;
    background-color:#00513B {}
    outline: none!important;
    text-decoration: none!important;
    border: none;
    color: #ffffff;
    margin-left: 10px;
    display: block;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: auto; }
    &:hover {
      background-color: #003F31; } } }

.embed-responsive-item {
  object-fit: cover; }

.card-default {
  display: block;
  text-decoration: none!important;
  transition: 0.3s ease-in;
  .thumbnail {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
    border-radius: 4px;
    transition: 0.3s ease-in; }

  .small-title {
    padding: 12px 0;
    border-bottom: 1px solid #CC9D33;
    font-size: 12px;
    color: #6A6A6A;
    font-weight: 900; }

  .title {
    font-size: 24px;
    color: #00513B;
    line-height: 30px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 900;
    margin-top: 10px;
    span {
      position: relative; } }

  p {
    font-size: 14px;
    color: #7E7E7E;
    line-height: 24px;
    max-height: 96px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; }

  &:hover {
    .thumbnail {
      transform: scale(0.96);
      backface-visibility: hidden;
      transform-origin: 50% 50%; }
    .title {
      span {
        background: rgba(246,233,184,1);
        background: linear-gradient(180deg, rgba(246,233,184,0) 0%, rgba(246,233,184,0.5) 60%, rgba(204,157,51,1) 100%); } } } }

hr {
  border-color: #CC9D33; }

.fw-900 {
  font-weight: 900!important; }

.text-sm {
  font-size: 18px;
  letter-spacing: 0.08em; }

.profile-detail-karyawan {
  background-color: #EFEFEF;
  padding: 120px 0 100px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding-top: 100px; }

  .col-lg-6 {
    padding: 0 30px;
    @media (max-width: 991px) {
      padding: 0 15px; } } }

.name {
  color: #00513B;
  font-weight: 900;
  margin-top: 30px; }

.status {
  font-weight: 900;
  color: #6A6A6A; }

.quotes {
  margin-top: 30px;
  svg {
    margin-bottom: 15px; }
  h4 {
    font-style: italic;
    color: #00513B;
    span {
      background: rgba(246,233,184,1);
      background: linear-gradient(180deg, rgba(246,233,184,0) 0%, rgba(246,233,184,0.5) 60%, rgba(204,157,51,1) 100%); } } }

.text-html {
  > img {
    border-radius: 16px; } }
.list-of-excellences {
  > p {
    max-width: 80%;
    margin: 0 auto;
    font-size: 18px;
    @media (max-width: 991px) {
      max-width: 100%;
      font-size: 16px; } }
  ul {
    list-style: none;
    padding: 0;
    margin: 60px 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    .list {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      padding: 0 30px 60px;
      text-align: center;
      @media (max-width: 991px) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px 30px; }
      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%; }

      img {
        max-width: 160px;
        margin: auto;
        @media (max-width: 767px) {
          max-width: 120px; } }

      h4 {
        font-weight: 900;
        color: #00513B;
        font-size: 24px;
        margin-top: 15px;
        font-family: 'Avenir black';
        @media (max-width: 991px) {
          font-size: 20px; } }
      p {
        font-size: 20px;
        @media (max-width: 991px) {
          font-size: 18px; } } } } }

.section-produk-other {
  &-inner {
    background-color: #EFEFEF;
    padding: 60px 0 100px;
    @media (max-width: 991px) {
      padding: 60px 0; } } }



.list-produk-other {
  > div {
    margin-bottom: 30px; }

  .list {
    position: relative;
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 991px) {
      height: 100%;
      display: flex;
      flex-direction: column; }

    .thumbnail {
      @media (max-width: 991px) {
        &:before {
          padding-top: 60%; } }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 991px) {
          object-position: bottom right; } } }

    .desc {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 30px;
      width: 60%;
      color: #ffffff;
      font-family: 'Avenir Roman';
      @media (max-width: 1199px) {
        padding: 15px; }
      @media (max-width: 991px) {
        position: relative;
        color: #414141;
        background-color: #fff;
        width: 100%;

        flex: 1; }
      img {
        width: 70px;
        margin-bottom: 5px; }

      h4 {
        font-family: 'Avenir Black';
        font-weight: 900;
        color: #ffffff;
        font-size: 22px;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          color: #414141; } }
      .btn-outline {
        box-shadow: inset 0px 0px 0px 1px #ffffff;
        color: #ffffff;
        @media (max-width: 991px) {
          box-shadow: inset 0px 0px 0px 1px #00513B;
          color: #00513B; } } } } }

.section-download {
  background-color: #fff;
  padding: 60px 0;
  text-align: center;
  h3 {
    font-weight: 900;
    color: #000000; }
  .btn-outline {
    margin: 30px auto 0; } }

.list-button-contact {
  list-style: none;
  padding: 30px 0;
  margin: 0;
  li {
    display: flex;
    flex-direction: column; } }

.btn-icon-hover {
  background-color: #00513B;
  border-radius: 30px;
  max-width: none;
  display: inline-flex;
  margin-bottom: 30px;
  padding: 10px 30px;
  p {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500; }
  svg {
    width: 28px; } }

.gradient-red {
    background: #78072B!important;
    background: radial-gradient(circle, #78072B 0%, #990937 35%, #9A0937 50%, #78072B 100%)!important; }

.list-mms-products {
  padding: 60px 0 40px;
  .card-product {
    margin-bottom: 30px; } }

.bg-gray {
  background-color: #EFEFEF!important; }

.bg-green {
  background-color: #00513B!important; }


.produk-mms-detail {
  padding: 150px 0 100px;
  background-color: #F6E9B8;
  @media (max-width: 991px) {
    padding: 120px 0 80px; }
  @media (max-width: 767px) {
    padding: 100px 0 80px; }

  .product-image {
    &::before {
      display: none; }
    &:hover {
      &::before {
        display: none; } } }

  .product-mms-desc {
    padding: 0 50px;
    @media (max-width: 991px) {
      padding: 30px 0 0; }
    img {
      max-width: 220px;
      @media (max-width: 991px) {
        max-width: 150px; } }
    h1 {
      font-weight: 900;
      color: #00513B;
      margin-top: 30px; }
    p {
      color: #000000;
      line-height: 1.8; } } }

.content-job-wrapper {
  border-radius: 8px;
  border: 1px solid #CC9D33;
  overflow: hidden;
  .label {
    background-color: rgba(188, 221, 197, 0.6);
    color: #00513B;
    font-weight: 900;
    padding: 20px 30px;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 16px;
      padding: 15px!important; } } }

.panel-job-lists {
  .expand {
    .icon {
      transform: rotate(-180deg); } }

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px 30px;
    border-bottom: 1px solid #EFEFEF;
    @media (max-width: 767px) {
      font-size: 16px;
      padding: 15px; }

    .icon {
      svg {
        fill: #E8BE70;
        height: 24px;
        transform: rotate(90deg);
        @media (max-width: 767px) {
          height: 18px; } } }

    h4 {
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      color: #7E7E7E;
      padding-right: 50px;
      flex: 1;
      @media (max-width: 767px) {
        padding-right: 30px;
        font-size: 16px; } } }

  .desc {
    display: none;
    background-color: #EFEFEF;
    padding: 20px 30px;
    @media (max-width: 767px) {
      font-size: 15px; }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      color: #7E7E7E;

      li {
        line-height: 1.8;
        padding-left: 20px;
        position: relative;

        &::before {
          content: "\2022";
          font-size: 20px;
          line-height: 1.5;
          color: #00513B;
          font-weight: bold;
          width: 1em;
          position: absolute;
          left: 0; }

        > ul {
          margin: 0;
          padding-left: 0;
          padding-top: 0;
          li {
            @media (max-width: 767px) {
              padding-left: 25px; }

            &::before {
              content: "";
              background-color: #00513B;
              font-weight: bold;
              width: 20px;
              height: 3px;
              border-radius: 2em;
              position: absolute;
              top: 10px;
              left: 0;
              @media (max-width: 767px) {
                width: 14px; } } } } } } }

  .content:last-child {
    .title {
      border: none; } } }

.form-group {
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-bottom: 1px solid #EFEFEF;
  position: relative;
  margin: 0;
  @media (max-width: 767px) {
    padding: 30px 15px; }

  label {
    color: #00513B;
    font-weight: 900;
    font-size: 14px;
    span {
      color: #BE0909; } }

  input, textarea {
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    padding: 10px 20px;
    background-color: #F9F9F9;
    outline-color: #00513B;
    @media (max-width: 767px) {
      padding: 10px 15px;
      font-size: 14px; } }


  label.error {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    font-size: 13px;
    font-style: italic;
    color: #BE0909;
    width: 100%;
    left: 30px;
    @media (max-width: 767px) {
      left: 15px; } }


  input.datepicker {
    padding-left: 40px; }
  .icon-date {
    position: absolute;
    left: 45px;
    top: 72px;
    @media (max-width: 767px) {
      left: 28px; } } }

.input-cv-wrapper {
  display: flex;
  align-items: center;
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding: 0;
    width: 100%;
    cursor: pointer; }

  .btn-upload {
    position: relative;
    border-radius: 5px;
    border: 1px solid #000000;
    font-weight: 900;
    color: #000000;
    padding: 7px 15px; }

  .text-file {
    margin-left: 20px;
    color: rgba(0,0,0,.3); } }

.input-cv-file-name {
  align-items: center;
  margin-top: 20px;
  display: none;
  &.show {
    display: flex; }
  p {
    margin: 0;
    color: #000000; }
  svg {
    width: 24px;
    margin-right: 10px;
    fill: #545F6E; } }



.radio-button-wrapper {
  .checkbox {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    color: #707070;
    font-size: 16px;
    margin-right: 30px;
    margin-top: 20px;
    &:last-child {
      margin-right: 0; }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .radio-button:after {
        opacity: 1; }
      &:checked ~ .radio-button {
        background-color: #00513B;
        border-color: #00513B; } }

    .radio-button {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      border: 2px solid #E8E7E7;
      border-radius: 50%;
      transition: 0.3s ease-in;

      &:after {
        content: "";
        position: absolute;
        width: 13px;
        height: 8px;
        top: 3px;
        left: 2px;
        border-width: 1px;
        border-color: transparent transparent #ffffff #ffffff;
        opacity: 0;
        transition: 0.3s ease-in;
        transform: rotate(-45deg);
        border-style: solid; } }

    &:hover {
      .radio-button {
        background-color: #E8E7E7; } } } }

.datepicker-dropdown {
  padding: 15px; }

.datepicker td,
.datepicker th {
  padding: 10px;
  font-size: 14px;
  border-radius: 0; }
.datepicker table tr td span {
  height: 50px;
  line-height: 50px;
  border-radius: 0; }
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover {
  background-image: none; }
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active[disabled], .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #00513B; }

.select2-container--default {
  .select2-selection--single {
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    background-color: #F9F9F9;
    height: auto;
    outline-color: #00513B;
    .select2-selection__placeholder {
      color: #636c72; }

    .select2-selection__rendered {
      line-height: 1.5;
      color: #000000;
      height: 100%;
      padding: 10px 20px;
      @media (max-width: 767px) {
        padding: 10px 15px;
        font-size: 14px; } }

    .select2-selection__arrow {
      height: 100%;
      b {
        border: solid #6A6A6A;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        margin-left: -8px;
        margin-top: -6px; } } }
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border: solid #6A6A6A;
          border-width: 0 2px 2px 0;
          padding: 3px;
          transform: rotate(-135deg);
          margin-top: -4px; } } } } }

.select2-results__option {
  padding: 5px 20px;
  @media (max-width: 767px) {
    padding: 5px 15px;
    font-size: 14px; } }
.select2 {
  outline-color: #00513B; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00513B; }

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
  border-radius: 4px!important;
  overflow: hidden;
  border: 0!important;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16); }

.alert-toast {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255, 0.8);
  z-index: 11;
  padding: 30px;
  &-inner {
    border-radius: 10px;
    background-color: #F6E9B8;
    max-width: 900px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    padding: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    text-align: center;
    @media (max-width: 767px) {
      min-height: auto;
      padding: 60px 30px; }

    img {
      max-width: 200px;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        max-width: 120px; } }
    p {
      font-size: 24px;
      color: #00513B;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 20px; } }
    h3 {
      color: #000000;
      @media (max-width: 767px) {
        font-size: 24px; } } } }

.sitemap-list {
  margin-top: 20px;
  list-style: none;
  padding-left: 0;
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px; }

  li {
    margin-bottom: 20px; }
  p {
    margin-bottom: 20px; }
  a {
    color: #414141;
    text-decoration: none;
    &:hover {
      color: #E8BE70; } } }

.embed-responsive-item {
  object-fit: cover; }
