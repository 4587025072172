// FAQ Styles
.panel-faq {
  margin-top: 50px;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #EFEFEF;

  .expand {
    .icon {
      transform: rotate(-180deg); } }

  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    padding: 50px;
    border-bottom: 1px solid #EFEFEF;
    @media (max-width: 767px) {
      padding: 20px; }

    .icon {
      svg {
        fill: #E8BE70;
        height: 24px;
        transform: rotate(90deg);
        @media (max-width: 767px) {
          height: 18px; } } }

    h4 {
      margin: 0;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 900;
      color: #7E7E7E;
      padding-right: 50px;
      flex: 1;
      @media (max-width: 767px) {
        font-size: 16px;
        padding-right: 30px; } } }

  .desc {
    display: none;
    background-color: #EFEFEF;
    padding: 50px;
    @media (max-width: 767px) {
      padding: 20px;
      font-size: 15px; }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      color: #7E7E7E;

      li {
        line-height: 1.8;
        padding-left: 20px;
        position: relative;

        &::before {
          content: "\2022";
          font-size: 20px;
          line-height: 1.5;
          color: #00513B;
          font-weight: bold;
          width: 1em;
          position: absolute;
          left: 0; }

        > ul {
          margin: 0;
          padding-left: 0;
          padding-top: 0;
          li {
            @media (max-width: 767px) {
              padding-left: 25px; }

            &::before {
              content: "";
              background-color: #00513B;
              font-weight: bold;
              width: 20px;
              height: 3px;
              border-radius: 2em;
              position: absolute;
              top: 10px;
              left: 0;
              @media (max-width: 767px) {
                width: 14px; } } } } } } }

  .content:last-child {
    .title {
      border: none; } } }
