// Distributor Styles
.list-distributor {
  > div {
    margin: 0 -8px 0; }

  .lists {
    margin: 0;
    padding: 0;
    > div {
      padding: 0 8px;
      margin-bottom: 16px; } } }

.distributor-map {
  img {
    width: 100%; } }
