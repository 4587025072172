// Modal Custom Styles
.modal-dialog {
  transform: none!important;
  max-width: 100%;
  margin: 0;
  height: 100%; }


.modal-content {
  border-radius: 0;
  border: none;
  background-color: transparent;
  transition: 0.3s ease-in; }

.button-close-modal {
  position: absolute;
  text-transform: uppercase;
  color: #00513B;
  font-weight: 700;
  display: flex;
  align-items: center;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px 10px;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
  z-index: 9;
  top: 30px;
  img {
    margin-right: 6px; } }

.slick-arrow {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: #00513B;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    top: 50%; }

  img {
    width: 13px; }


  &.slick-prev {
    left: 20%;
    @media (max-width: 1440px) {
      left: 10%; }
    @media (max-width: 1360px) {
      left: 30px; } }

  &.slick-next {
    right: 20%;
    @media (max-width: 1440px) {
      right: 10%; }
    @media (max-width: 1360px) {
      right: 30px; } } }

.slider-modal {

  .slide {
    display: flex!important;
    flex-direction: column;
    height: 100vh;
    padding: 30px 0 0;
    align-items: center;
    outline: none;
    background-color: rgba(255,255,255, 0.8);

    > .container {
      height: auto;
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 80px;
      @media (max-width: 991px) {
        width: calc(100% - 30px);
        padding: 0;
        align-items: flex-end; }

      .thumbnail {
        // box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16)
        border-radius: 4px;
        @media (max-width: 991px) {
          &:before {
            padding-top: 100%; } } }

      img {
        object-fit: contain;
        object-position: center; } }


    .desc-wrapper {
      height: auto;
      background-color: #F6E9B8;
      width: 100%;
      margin-top: 40px;
      .desc {
        padding: 15px;
        font-size: 20px;
        @media (max-width: 767px) {
          font-size: 16px; } }
      p {
        margin: 0;
        text-align: center;
        color: #070606; } } } }

