// Product Categories Style
.section-quotes {
  position: relative;
  &-wrapper {
    max-width: 870px;
    margin: auto;
    width: 100%;
    padding: 0 15px; }
  &-inner {
    padding: 80px 0 150px;
    background-color: #EFEFEF;
    text-align: center;
    @media (max-width: 580px) {
      padding-top: 60px; }

    svg {
      margin-bottom: 30px; }

    h2 {
      font-size: 22px;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.02rem;
      line-height: 1.5;
      @media (max-width: 991px) {
        font-size: 19px; }
      @media (max-width: 580px) {
        font-size: 16px; } }

    a {
      margin: 30px auto 0; } }

  .deco-wave {
    width: 100%;
    margin-bottom: -1px;
    display: flex;
    align-items: center;
    margin: 0;
    svg {
      width: 100%;
      object-fit: cover;
      object-position: bottom center;
      fill: #EFEFEF; } } }


