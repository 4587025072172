// Foto styles
.foto-wrapper {
  .card-list {
    .desc {
      top: auto;
      bottom: 0;
      @media (max-width: 991px) {
        transform: none; } }

    .title {
      transform: translateY(40px);
      @media (max-width: 991px) {
        transform: none; } }

    .thumbnail {
        img {
          transform: scale(1);
          @media (max-width: 991px) {
            transform: scale(1.1); } } }

    &:hover {
      .desc {
        transform: none;

        .title {
          transform: translateY(0); } }

      .thumbnail {
        img {
          transform: scale(1.1);
          backface-visibility: hidden;
          transform-origin: 50% 50%; } } } } }

.foto-detail-lists {
  padding: 130px 0 100px;
  background-color: #F6E9B8;
  @media (max-width: 991px) {
    padding: 120px 0 80px; }
  @media (max-width: 767px) {
    padding: 100px 0 80px; }

  .title {
    font-weight: 900;
    color: #00513B;
    letter-spacing: 0.02em;
    font-size: 35px;
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 28px; } } }

.card-foto-detail {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    @media (max-width: 991px) {
      opacity: 1; } }

  .thumbnail {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-out;
      @media (max-width: 991px) {
        transform: scale(1.1); } } }

  .action-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 0;
    top: 0;
    color: #ffffff;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out;
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    @media (max-width: 991px) {
      font-size: 16px;
      opacity: 1;
      visibility: visible; }

    span {
      margin-top: 5px; } }

  &:hover {
    &::before {
      opacity: 1; }

    .thumbnail {
      img {
        transform: scale(1.1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } }

    .action-modal {
      opacity: 1;
      visibility: visible; } } }


