#modal-article {
  .modal-content {
    background-color: rgba(0, 0, 0, 0.85);
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    .content-article {
      padding: 15px;
      max-width: 600px;
      width: 100%;
      text-align: center; }

    .modal-title {
      background-color: #003F31;
      border-radius: 4px;
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.4px;
      font-weight: 700;
      padding: 8px 6%;
      margin-bottom: 20px;
      display: inline-block;
      @media (max-width: 767px) {
        font-size: 16px;
        padding: 8px 10%; } }

    .thumbnail {
      border-radius: 8px;
      overflow: hidden;
      position: relative;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        color: #ffffff;
        width: 24px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.85);
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        img {
          width: 100%; } } }

    .title-page {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      margin: 20px 0; }

    .btn-outline {
      background-color: #fff; } } }

