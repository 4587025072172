//section inspiration styles
.section-inspiration {
  margin-top: -70px;
  &-inner {
    background-color: #ffffff;
    overflow: hidden;
    padding-top: 30px; }

  .deco-wave {
    width: 100%;
    margin-bottom: -1px;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      object-fit: contain;
      object-position: bottom center;
      fill: #ffffff; } }

  .inspiration-lists {
    list-style: none;
    padding: 20px 0 0;
    margin: 0 -2px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media (max-width:767px) {
      margin: 0; }

    li {
      padding: 2px;
      flex: 0 0 50%;
      @media (max-width:767px) {
        flex: none;
        width: 100%;
        padding: 0 0 4px; } } } }

