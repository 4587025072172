// Hero Wrapper Style
.hero-wrapper {
  position: relative;
  height: 100vh;
  @media (max-width: 991px) {
    height: auto; }


  .hero-slider {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    @media (max-width: 991px) {
      height: auto;
      position: relative; }

    .slide {
      position: relative;
      outline: none;
      height: 100vh;
      @media (max-width: 991px) {
        height: auto;
        .container {
          // height: 100%
          // min-height: 40vh
          height: auto; } }

      .thumbnail {
        @media (max-width: 991px) {
          // height: 100%
          // position: absolute
          height: 60vh;
          position: relative;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover; } } }

      .desc {
        position: absolute;
        z-index: 2;
        padding: 0;
        width: 40%;
        @media (max-width: 991px) {
          width: 100%;
          left: 0;
          position: relative;
          top: auto!important;
          padding: 45px 30px; }
        @media (max-width: 580px) {
          padding: 30px 5px; }

        &.white-title {
          color: #ffffff;
          @media (max-width: 991px) {
            color: #414141; }

          .title {
            color: #ffffff;
            @media (max-width: 991px) {
              color: #017A5A; } }
          .btn-outline {
            color: #ffffff;
            box-shadow: inset 0px 0px 0px 1px #ffffff;
            @media (max-width: 991px) {
              color: #017A5A;
              box-shadow: inset 0px 0px 0px 1px #017A5A; } } }


        .title {
          color:#017A5A {}
          font-size: 50px;
          font-weight: 900;
          @media (max-width: 1280px) {
            font-size: 42px; }
          @media (max-width: 1199px) {
            font-size: 37px; }
          @media (max-width: 480px) {
            font-size: 34px; }
          @media (max-width: 320px) {
            font-size: 28px; } } }

      &.left-desc {
        .desc {
          top: 30%;
          @media (max-width: 320px) {
            top: 22%;
            p {
              font-size: 14px; } } }

        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: 80% 100%; } } } }

      &.center-desc {
        text-align: center;
        .desc {
          top: 30%;
          left: 50%;
          transform: translateX(-50%);
          @media (max-width: 320px) {
            top: 22%;
            p {
              font-size: 14px; } }

          img {
            margin: auto; }

          a {
            margin: auto; } }
        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: center center; } } } }

      &.right-desc {
        text-align: right;
        @media (max-width: 991px) {
          text-align: left; }
        .desc {
          top: 30%;
          right: 6%;
          @media (max-width: 1300px) {
            right: 4%; }
          @media (max-width: 320px) {
            top: 22%;
            p {
              font-size: 14px; } }

          img {
            margin-left: auto;
            @media (max-width: 991px) {
              max-width: 150px;
              margin-left: 0; }
            @media (max-width: 580px) {
              max-width: 150px; } }
          a {
            margin-left: auto;
            @media (max-width: 991px) {
              margin-left: 0; } } }

        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: 10% 100%; } } } } }

    .slick-slide {
      .thumbnail {
        img {
          width: 100%;
          max-height: 100vh;
          object-fit: cover; } } } }


  .slider-controls {
    position: fixed;
    bottom: 50px;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      position: absolute;
      bottom: auto;
      top: 55vh; }
    // @media (max-width: 320px)
    //   bottom: 20px

    .slick {
      &-arrow {
        border: none;
        text-indent: -999px;
        padding: 0;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        width: 24px;
        height: 24px;
        border: 2px solid #00513B;
        border-radius: 50%;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); }

      &-next {
        background: #ffffff  image('icons/icon-arrow-right.svg') no-repeat;
        background-size: 9px;
        background-position: center center;
        right: -42px;
        order: 1; }


      &-prev {
        background: #ffffff image('icons/icon-arrow-left.svg') no-repeat;
        background-size: 9px;
        background-position: center center;
        left: -42px; }


      &-dots {
        padding: 0;
        list-style: none;
        display: flex;
        height: 12px;
        margin: 0 20px;

        button {
          border: none;
          text-indent: -99px;
          padding: 0;
          border: 1px solid #00513B;
          border-radius: 12px;
          width: 12px;
          height: 12px;
          background-color: transparent;
          margin: 0 3px;
          cursor: pointer;
          outline: none;
          overflow: hidden; }
        .slick-active {
          button {
            background-color: #00513B; } } } } } }
