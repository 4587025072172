// Product Categories Style
.product-categories {
  background-color: #fff;
  position: relative;
  &-inner {
    transform: translateY(-80px);
    margin: 0 0 -80px;

    > div {
      padding: 0 8px 15px;
      @media (max-width: 767px) {
        padding: 0 0 15px; } }

    .card-category {
      &:before {
        opacity: 0; }
      &:hover {
        &:before {
          opacity: 1; } } } } }
