// Video Styles
.video-wrapper {
  > div {
    margin-bottom: 0; } }

.video-list {
  border: 1px solid #CC9D33;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column; }

  .thumbnail {
    width: 50%;
    flex-shrink: 0;
    @media (max-width: 991px) {
      width: 100%; } }

  .desc {
    display: flex;
    flex-direction: column;
    padding: 30px;
    letter-spacing: 0.05em;
    background-color: #FAFAFA;
    @media (max-width: 991px) {
      padding: 15px; }

    .title {
      font-size: 24px;
      font-weight: 900;
      color: #00513B; }
    p {
      font-size: 14px;
      font-weight: 300;
      flex: 1; }

    .icon-play {
      align-self: flex-end;
      svg {
        fill: #00513B; } } } }

.video-column {
  padding: 0;

  .video-list {
    flex-direction: column; }

  .thumbnail {
    width: 100%; }

  .desc {
    flex-direction: column; }

  p {
    max-width: 85%;
    @media (max-width: 991px) {
      max-width: none; } }

  .icon-play {
    margin-top: -36px;
    @media (max-width: 991px) {
      margin-top: 0; } } }

.video-detail {
  padding: 160px 0;
  background-color: #F6E9B8;
  @media (max-width: 991px) {
    padding: 120px 0 80px; }
  @media (max-width: 767px) {
    padding: 100px 0 80px; }
  @media (max-width: 320px) {
    iframe {
      width: calc(100vw - 10px)!important; } } }

.content-video-detail {
  margin-top: 30px;
  text-align: center;
  letter-spacing: 0.1em;

  p {
    margin: 0; }

  h1, h2, h3, h4, h5 {
    color: #070606;
    font-weight: 500; } }

