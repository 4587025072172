// Product Styles
.section-produk {
  text-align: center;
  .produk-thumbnail {
    > div {
      max-width: 250px; } }
  img {
    max-width: 100%; }

  .btn-outline {
    margin: 15px auto 0; } }

.list-product-wrapper {
  padding: 40px 0;
  @media (max-width: 991px) {
    padding-top: 0; } }


.product-detail {
  background: rgb(0,60,30);
  background: linear-gradient(90deg, rgba(0,60,30,1) 0%, rgba(0,98,41,1) 30%, rgba(0,98,41,1) 70%, rgba(0,60,30,1) 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 30px;
  border-radius: 8px;
  overflow: hidden;
  margin: 160px 0 0;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 30px 15px;
    margin-top: 120px; }
  @media (max-width: 767px) {
    margin-top: 100px; }


  .col-lg-6 {
    padding: 0;
    > .thumbnail {
      flex-shrink: 0;
      margin-right: 40px;
      @media (max-width: 991px) {
        margin-right: 0;
        margin-bottom: 30px;
        text-align: center; }

      img {
        object-fit: contain; } } }

  .content {
    color: #ffffff;

    h3 {
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.05em; }

    .desc {
      font-weight: 300;
      letter-spacing: 0.05em;
      line-height: 1.8;
      padding-bottom: 30px;
      margin-bottom: 30px;
      position: relative;
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: repeating-linear-gradient(to right,#ffffff 0,#ffffff 13px,transparent 13px,transparent 26px); } } } }

.links-product {
  h4 {
    font-weight: normal;
    font-size: 18px; }

  .store-lists {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 65px;
      margin-right: 5px;
      margin-bottom: 5px;
      @media (max-width: 991px) {
        width: calc(13% - 10px);
        margin: 5px; }
      @media (max-width: 767px) {
        width: calc(50% - 10px); } } } }

.link-stores {
  width: 100%;
  height: 100%;
  display: block;
  background-color: #EFEFEF;
  border-radius: 8px;
  img {
    transition: 0.3s ease-out; }

  &:hover {
    .thumbnail {
      img {
        transform: scale(1.1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } } } }

.tags {
  list-style: none;
  padding: 0;
  margin: 0 0 15px;
  display: flex;
  flex-wrap: wrap; }

.badge-tag {
  background-color: #E8BE70;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.05em;
  border-radius: 4px;
  margin: 0 5px 5px 0;
  font-size: 12px;
  line-height: 1.3;
  padding: 2px 10px; }

.list-products {
  margin: 0;
  > div {
    padding: 10px;
    @media (max-width: 767px) {
      padding: 15px; } } }

.product-image {
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: rgb(34,142,64);
  background: radial-gradient(circle, rgba(34,142,64,1) 0%, rgba(34,142,64,1) 55%, rgba(13,111,39,1) 85%, rgba(7,103,33,1) 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  .action-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    left: 0;
    top: 0;
    color: #ffffff;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-out;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    @media (max-width: 991px) {
      font-size: 16px;
      // opacity: 1
 }      // visibility: visible

    > img {
      width: 45px; }

    span {
      margin-top: 5px; } }

  &:hover {
    &::before {
      opacity: 1; }

    .action-modal {
      opacity: 1;
      visibility: visible; } } }

.card-product {

  h4 {
    text-align: center;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #070606;
    margin: 0; } }

.filter {
  &-sidebar {
    @media (max-width: 991px) {
      position: fixed;
      bottom: 0;
      z-index: 9;
      transform: translateY(100%);
      transition: 0.3s ease-in;
      padding: 0; } }

  &-product {
    border-radius: 8px;
    border: 1px solid #E8BE70;
    overflow: hidden;
    padding: 15px 13px 0;
    margin-top: 10px;
    background-color: #fff;
    @media (max-width: 991px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      height: 85vh;
      overflow-y: auto; } }

  &-title {
    background-color: #EFEFEF;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    font-weight: 900!important; }

  &-checkbox {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;

    .checkbox {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      color: #707070;
      font-size: 16px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .radio-button:after,
        &:checked ~ .checkmark-tick:after {
          opacity: 1; }
        &:checked ~ .radio-button, {
          background-color: #00513B;
          border-color: #00513B; } }

      .checkmark-tick {
        position: absolute;
        height: 20px;
        width: 20px;
        border: 2px solid  #E8E7E7;
        border-radius: 5px;
        left: 0;
        &::after {
          content: "";
          position: absolute;
          opacity: 0;
          left: 5px;
          top: 1px;
          width: 7px;
          height: 12px;
          border: solid  #00513B;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg); } }

      .radio-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border: 2px solid #E8E7E7;
        border-radius: 50%;
        transition: 0.3s ease-in;

        &:after {
          content: "";
          position: absolute;
          width: 13px;
          height: 8px;
          top: 3px;
          left: 2px;
          border-width: 1px;
          border-color: transparent transparent #ffffff #ffffff;
          opacity: 0;
          transition: 0.3s ease-in;
          transform: rotate(-45deg);
          border-style: solid; } }

      &:hover {
        .radio-button {
          background-color: #E8E7E7; } } } } }

.filter-list-products {
  .list-products {
    @media (max-width: 991px) {
      margin: 0 -10px; }
    @media (max-width: 767px) {
      margin: 0 -15px; } } }

.filter-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 991px) {
    justify-content: flex-end; }

  button {
    margin: 0 5px 20px; } }

.button-filter-mobile {
  display: none;
  padding: 15px;
  width: 100%;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

  .filter-trigger {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #ffffff;
    padding: 4px 12px;
    height: 35px;
    font-weight: normal;
    letter-spacing: 0.05em;
    text-transform: none;
    margin-right: 5px;
    margin-bottom: 5px;
    svg, img {
      width: 25px;
      height: 25px;
      fill:#ffffff {}
      margin-right: 5px; } }

  .badge-tag {
    padding: 2px 12px;
    font-size: 14px;
    border-radius: 7px;
    height: 35px;
    display: flex;
    align-items: center;
    font-weight: normal; } }
