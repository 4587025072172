// section collaboration styles
.section-collaboration {
  margin-top: -70px;
  &-inner {
    background-color: #EFEFEF;
    overflow: hidden;
    padding-top: 60px;
    @media (max-width: 991px) {
      padding-bottom: 40px; }
    @media (max-width: 320px) {
      padding-bottom: 20%; } }

  .deco-wave {
    width: 100%;
    margin-bottom: -1px;
    display: flex;
    align-items: center;

    svg {
      width: 100%;
      object-fit: contain;
      object-position: bottom center;
      fill: #EFEFEF; } }

  .top-title {
    span {
      background-color: #EFEFEF; } }

  .slick-list {
    padding: 0!important;
    overflow: visible; }

  .section-collaboration-slider {
    padding: 10% 0 20%;
    @media (max-width: 991px) {
      padding: 20% 20px; }

    .slick-arrow {
      position: absolute;
      z-index: 9;
      top: 45%;
      transform: translateY(-50%);
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 50%;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      cursor: pointer;
      @media (max-width: 1440px) {
        width: 60px;
        height: 60px;
        img {
          max-width: 15px; } }

      &:hover {
        background-color: #fff; }

      &.slick-prev {
        left: -20%;
        @media (max-width: 1440px) {
          left: -18.5%; }
        @media (max-width: 991px) {
          left: -15%; }
        @media (max-width: 767px) {
          left: -15px; } }

      &.slick-next {
        right: -20%;
        @media (max-width: 1440px) {
          right: -18.5%; }
        @media (max-width: 991px) {
          right: -15%; }
        @media (max-width: 767px) {
          right: -15px; } } } }



  .slide {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    height: 100%;

    .thumbnail {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

      @media (max-width: 767px) {
        &:before {
          padding-top: 60%; }
        img {
          object-position: bottom right; } } }

    .desc {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      padding: 40px;
      width: 50%;
      color: #ffffff;
      font-family: 'Avenir Roman';
      @media (max-width: 1199px) {
        padding: 30px;
        width: 60%; }
      @media (max-width: 991px) {
        position: relative;
        color: #414141;
        background-color: #fff;
        width: 100%; }
      @media (max-width: 767px) {
        padding: 15px; }

      .title {
        font-family: 'Avenir black';
        font-size: 30px;
        @media (max-width: 1199px) {
          font-size: 24px; }
        @media (max-width: 767px) {
          font-size: 20px; } }

      .btn-outline {
        box-shadow: inset 0px 0px 0px 1px #ffffff;
        color: #ffffff;
        @media (max-width: 991px) {
          box-shadow: inset 0px 0px 0px 1px #00513B;
          color: #00513B; } }

      > img {
        width: 120px;
        margin-bottom: 10px;
        @media (max-width: 1199px) {
          width: 100px; } } } }



  .slick-slide {
    transition: transform 0.3s;

    &.slick-center {
      transform: scale(1.3);
      z-index: 9;
      position: relative;
      @media (max-width: 767px) {
        transform: scale(1.1); }

      .slide {
        box-shadow: 8px 8px 8px 0px rgba(0,0,0,0.16); } } } }
