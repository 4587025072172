// Section Brands Styles
.section-brands {
  padding: 60px 0 120px;
  background-color: #fff;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // @media (max-width: 580px)
    //   justify-content: flex-start

    li {
      padding: 20px;
      flex: 0 0 33.33%;
      text-align: center;
      @media (max-width: 580px) {
        padding: 20px 0; }

      .btn-gradient {
        opacity: 0;
        visibility: hidden;
        margin: 30px auto 0;
        transition: 0.3s ease-in-out; }

      &:hover {
        img {
          transform: scale(1.1); }
        .btn-gradient {
          visibility: visible;
          opacity: 1; } }



      img {
        max-width: 285px;
        width: 100%;
        transition: 0.3s ease-in-out; }

      // a
      //   display: block
      //   transition: 0.3s ease-in-out
      //&:hover
      //  img
 } } }      //    transform: scale(1.1)
